<script>
import modal from '/~/core/mdl'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import { formatDate } from '/~/utils/format/date'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useLocalization } from '/~/composables/localization'
export default {
  name: 'details-view-service-offer',
  components: {
    BaseLink,
    BaseIcon,
  },
  props: {
    offer: {
      type: Object,
      default: () => ({}),
    },
    featured: {
      type: Boolean,
      default: false,
    },
    retailer: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { redeemOffer: commhubRedeemOffer } = useCommunityHub()
    const { translate } = useLocalization()

    function redeemOffer() {
      try {
        commhubRedeemOffer(props.offer.id)
      } catch (error) {
        console.error(error)
      }
    }

    function showModalWithTrack() {
      modal.show('community-hub-offer', {
        props: {
          offer: props.offer,
          redeem: commhubRedeemOffer,
        },
      })
    }

    return {
      redeemOffer,
      showModalWithTrack,
      translate,
    }
  },
  data() {
    return {
      showFullDescription: false,
    }
  },
  computed: {
    isOfferTypeUrl() {
      return this.offer.type === 'url'
    },
    isOfferTypeHtml() {
      return this.offer && this.offer.type === 'html'
    },
  },
  methods: {
    expiry(date) {
      if (date) {
        const dateFormatted = formatDate('daymonthyear', date)

        return `Expires: ${dateFormatted}`
      }
    },
    showTermsModal() {
      modal.show('community-hub-offer-terms', {
        props: {
          terms: this.offer.terms,
        },
      })
    },
    toggleShowFullDescription() {
      this.showFullDescription = !this.showFullDescription
    },
  },
}
</script>

<template>
  <div
    v-if="offer"
    class="rounded-lg border bg-white p-2.5 text-base sm:border-none"
  >
    <div class="flex items-stretch justify-between">
      <div class="p-[15px]">
        <div v-if="offer.locations && offer.locations.length">
          <div v-for="(location, index) in offer.locations" :key="index">
            <a
              :href="`http://maps.apple.com/?q=${location.name}`"
              class="mb-[5px] inline-flex items-center text-sm text-eonx-neutral-800 opacity-75 hover:opacity-100"
            >
              <base-icon
                svg="plain/marker"
                class="mr-2.5 text-eonx-neutral-800"
                :size="16"
              />
              <span>{{ location.name }}</span>
            </a>
          </div>
        </div>

        <div
          v-if="!isOfferTypeHtml"
          class="text-eonx-neutral-800"
          :class="{ 'mb-5 text-2xl': featured }"
        >
          <b>{{ offer.name }}</b>
        </div>
        <div
          v-if="offer.description"
          class="markup z-0 mt-[5px] overflow-hidden text-eonx-neutral-800"
          :class="{ 'h-11': !showFullDescription }"
          v-html="offer.description"
        />
        <div
          class="z-1 -mt-[15px] h-6 cursor-pointer bg-white"
          :class="{ hidden: showFullDescription }"
          style="filter: blur(0.6rem)"
          @click="toggleShowFullDescription"
        />
        <div v-if="offer.endsAt" class="mb-[5px] text-eonx-neutral-600">
          {{ expiry(offer.endsAt) }}
        </div>

        <template v-if="offer.terms">
          <div
            class="mt-2.5 cursor-pointer text-base font-bold leading-7 text-primary underline opacity-75 hover:no-underline hover:opacity-100"
            @click="showTermsModal"
          >
            See offer Terms {{ translate('common.and') }} Conditions
          </div>
        </template>
      </div>

      <template v-if="isOfferTypeUrl">
        <base-link
          v-if="offer.redirectPage"
          class="flex min-w-40 cursor-pointer items-center justify-center rounded-r-md border-l text-base font-bold text-primary opacity-75 hover:bg-gray-50 hover:opacity-100"
          :to="{
            name: `${$ext.name}-redirect`,
            params: {
              promotionUrl: offer.promotionUrl,
              content: offer.redirectContent,
              offer: offer.id,
              retailer,
            },
          }"
        >
          Redeem Now
        </base-link>

        <base-link
          v-else
          class="flex min-w-40 cursor-pointer items-center justify-center rounded-r-md border-l text-base font-bold text-primary opacity-75 hover:bg-gray-50 hover:opacity-100"
          :href="offer.promotionUrl"
          @click="redeemOffer"
        >
          Redeem Now
        </base-link>
      </template>

      <template v-else-if="!isOfferTypeHtml">
        <div
          class="flex min-w-40 cursor-pointer items-center justify-center rounded-r-md border-l text-base font-bold text-primary opacity-75 hover:bg-gray-50 hover:opacity-100"
          @click="showModalWithTrack"
        >
          Redeem Now
        </div>
      </template>
    </div>
  </div>
</template>
